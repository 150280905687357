import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Editor, EditorProvider, Toolbar } from 'lib/rte';
import { toastify } from 'common/components/Toastify';
import EditViewActionButtons from '../EditViewActionButtons';
import FormatAndAddExtendedMetadataSection from '../FormatAndAddExtendedMetadataSection';
import useFormatAndExtendedMetadata from '../hooks/useFormatAndExtendedMetadata';

const MAX_OPTIONS_COUNT = process.env.REACT_APP_ANSWER_OPTIONS_COUNT;

const MatchingEditView = ({ questionNode, questionNodeIndex, userMetadata, onSubmit, onDelete, hideActionButtons }) => {
  const { Caption, Options } = questionNode.qtiModel;
  const intl = useIntl();

  const [questionData, setQuestionData] = useState({
    Caption: Caption ?? '',
    Options: Options ?? [{ option: '', matchingOption: '' }],
  });

  const [formatAndExtendedMetadata, setFormatAndExtendedMetadata] = useFormatAndExtendedMetadata(questionNode);

  const handleChange = e => {
    const { name, value } = e.target || e;

    setQuestionData(prev => ({ ...prev, [name]: value }));
  };

  const handleAddOption = () => {
    if (questionData.Options.length < MAX_OPTIONS_COUNT) {
      setQuestionData(prev => ({
        ...prev,
        Options: [...prev.Options, { option: '', matchingOption: '' }],
      }));
    } else {
      const msg = intl.formatMessage({ id: 'warning.moreoptions' }, { count: MAX_OPTIONS_COUNT });
      toastify.showWarningToast(msg);
    }
  };

  const handleOptionsChange = (e, index) => {
    const { name, value } = e.target || e;
    const tempOptions = [...questionData.Options];
    tempOptions[index][name] = value;
    setQuestionData({ ...questionData, Options: tempOptions });
  };

  const handleRemoveOption = index => {
    if (questionData.Options.length > 2) {
      setQuestionData(prev => ({
        ...prev,
        Options: prev.Options.filter((_, i) => i !== index),
      }));
    }
  };

  return (
    <div className="m-2 border rounded p-3 bg-light">
      <EditorProvider>
        <Toolbar />
        <div id={questionNode.itemId}></div>
        <Form className="editmode paddingright10">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="mb-1" style={{ fontSize: '0.9em' }}>
              {intl.formatMessage({ id: 'label.matching.title' })}
            </Form.Label>
            <Editor
              name="Caption"
              value={questionData.Caption}
              placeholder={intl.formatMessage({ id: 'placeholder.matching.caption' })}
              className="mb-4"
              onChange={value => handleChange({ name: 'Caption', value })}
              maxLength={255}
            />

            <Form.Group className="mb-1">
              {questionData?.Options?.length > 0 &&
                questionData?.Options.map((optItem, index) => {
                  return (
                    <div key={index} style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                      <Form.Group style={{ marginBottom: '5px', width: '50%' }}>
                        <div>
                          <Editor
                            onChange={value => handleOptionsChange({ target: { name: 'option', value } }, index)}
                            value={optItem.option}
                            name={`option-${index}`}
                            placeholder={intl.formatMessage(
                              { id: 'placeholder.matching.columnA' },
                              { count: index + 1 }
                            )}
                            maxLength={255}
                            key={`option-${index}-${questionData.Options.length}`}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group style={{ marginBottom: '5px', width: '50%' }}>
                        <div>
                          <Editor
                            onChange={value =>
                              handleOptionsChange({ target: { name: 'matchingOption', value } }, index)
                            }
                            value={optItem.matchingOption}
                            name={`matchingOption-${index}`}
                            placeholder={intl.formatMessage(
                              { id: 'placeholder.matching.columnB' },
                              { count: index + 1 }
                            )}
                            maxLength={255}
                            key={`matchingOption-${index}-${questionData.Options.length}`}
                          />
                        </div>
                      </Form.Group>
                      <Button
                        className="opt-close-button highlight-icon-button "
                        title={intl.formatMessage({
                          id: 'message.remove',
                          defaultMessage: 'Delete Option',
                        })}
                        variant="link"
                        onClick={() => handleRemoveOption(index)}
                        disabled={questionData.Options.length <= 2}
                      >
                        <i className="bi bi-x-lg"></i>
                      </Button>
                    </div>
                  );
                })}
              <div className="d-flex justify-content-end">
                <Button onClick={handleAddOption}>
                  <FormattedMessage id="addOptionsforMultiplechoice" />
                </Button>
              </div>
            </Form.Group>

            <FormatAndAddExtendedMetadataSection
              data={formatAndExtendedMetadata}
              setData={setFormatAndExtendedMetadata}
              userMetadata={userMetadata}
            />
          </Form.Group>
        </Form>

        {!hideActionButtons && (
          <EditViewActionButtons
            index={questionNodeIndex}
            questionNode={questionNode}
            questionData={questionData}
            formatAndExtendedMetadata={formatAndExtendedMetadata}
            onSubmit={onSubmit}
            onDelete={onDelete}
          />
        )}
      </EditorProvider>
    </div>
  );
};

export default MatchingEditView;
