import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Editor, EditorProvider, Toolbar } from 'lib/rte';
import EditViewActionButtons from '../EditViewActionButtons';
import FormatAndAddExtendedMetadataSection from '../FormatAndAddExtendedMetadataSection';
import useFormatAndExtendedMetadata from '../hooks/useFormatAndExtendedMetadata';

const TrueFalseEditView = ({
  questionNode,
  questionNodeIndex,
  userMetadata,
  onSubmit,
  onDelete,
  hideActionButtons,
}) => {
  const { Caption, Options, CorrectAnswer } = questionNode.qtiModel;
  const intl = useIntl();

  const [questionData, setQuestionData] = useState({
    Caption: Caption ?? '',
    Options: Options ?? ['', '', '', ''],
    CorrectAnswer: CorrectAnswer ?? -1,
  });

  const [formatAndExtendedMetadata, setFormatAndExtendedMetadata] = useFormatAndExtendedMetadata(questionNode);

  const handleChange = e => {
    const { name, value } = e.target || e;

    setQuestionData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="m-2 border rounded p-3 bg-light">
      <EditorProvider>
        <Toolbar />
        <Form className="editmode paddingright10">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <b>
              <Form.Label className="mb-1">{intl.formatMessage({ id: 'label.trueFalse.title' })}</Form.Label>
            </b>

            <Editor
              className="mb-2"
              placeholder={intl.formatMessage({ id: 'placeholder.trueFalse.caption' })}
              value={questionData.Caption}
              onChange={value => handleChange({ name: 'Caption', value })}
            />

            <Form.Group className="mb-1 mt-3 d-flex flex-wrap">
              {questionData?.Options?.length > 0 &&
                questionData?.Options.map((optItem, index) => {
                  return (
                    <Form.Group key={index} className="mb-2 w-100 ">
                      <div className="d-flex align-items-center" tabIndex="0">
                        <Form.Check
                          type="radio"
                          checked={index == questionData.CorrectAnswer}
                          value={index}
                          className="me-2"
                          name="CorrectAnswer"
                          onChange={handleChange}
                          tabIndex="0"
                        />

                        <Form.Text style={{ fontSize: '1.1rem' }} className="mb-1 ">
                          {optItem}
                        </Form.Text>
                      </div>
                    </Form.Group>
                  );
                })}
            </Form.Group>
          </Form.Group>

          <FormatAndAddExtendedMetadataSection
            data={formatAndExtendedMetadata}
            setData={setFormatAndExtendedMetadata}
            userMetadata={userMetadata}
          />
        </Form>

        {!hideActionButtons && (
          <EditViewActionButtons
            index={questionNodeIndex}
            questionNode={questionNode}
            questionData={questionData}
            formatAndExtendedMetadata={formatAndExtendedMetadata}
            onSubmit={onSubmit}
            onDelete={onDelete}
          />
        )}
      </EditorProvider>
    </div>
  );
};

export default TrueFalseEditView;
