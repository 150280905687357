import { Button, Modal } from 'react-bootstrap';
import QuestionMapper from './questions/QuestionMapper';

const EditSavedQuestionModal = ({ question, onClose }) => {
  const handleSave = () => {
    onClose();
  };

  return (
    <Modal className="edit-saved-question-modal" show={true} onHide={onClose} backdrop="static" centered size="lg">
      <Modal.Header>
        <Modal.Title>Edit Question</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <QuestionMapper key={question.guid} questionNode={question} hideActionButtons />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button type="button" className="btn btn-primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditSavedQuestionModal;
