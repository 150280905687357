import { useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const ImportQuestion = () => {
  const [showImportModal, setShowImportModal] = useState(false);
  const [file, setFile] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleShowModal = () => {
    setShowImportModal(true);
    setSuccessMessage(''); // Clear success message when opening the modal
  };

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];

    // Clear alert and success messages initially
    setAlertMessage('');
    setSuccessMessage('');

    if (selectedFile) {
      // Check if the file is a ZIP file
      if (selectedFile.type === 'application/zip' || selectedFile.name.endsWith('.zip')) {
        setFile(selectedFile);
        setSuccessMessage('File uploaded successfully!'); // Set success message
      } else {
        // Set validation message for invalid file type
        setAlertMessage('Please select a ZIP file only.');
        setFile(null); // Reset file if invalid
      }
    } else {
      setAlertMessage('No file selected. Please select a file to upload.');
    }
  };

  const processZipFile = file => {
    // Implement your logic to unzip and process the file here.
    console.log('Processing ZIP file:', file);
  };

  const handleImport = () => {
    if (file) {
      // Process the file upload here
      console.log('File to upload:', file);
      // Close the modal after processing
      setShowImportModal(false);
      setFile(null); // Reset the file
    }
  };

  return (
    <>
      <Button className="color-black" variant="outline-light" onClick={handleShowModal}>
        <i className="fa-solid fa-arrow-down"></i>&ensp;
        <FormattedMessage id="message.import" />
      </Button>

      {/* Import Question Modal */}
      <Modal show={showImportModal} onHide={() => setShowImportModal(false)}>
        <Modal.Header className="modal-custom-header">
          <Modal.Title>
            <FormattedMessage id="importQuestions.title" defaultMessage="Import Questions" />
          </Modal.Title>
          <i title="Close" className="bi bi-x-lg" onClick={() => setShowImportModal(false)}></i>
        </Modal.Header>
        <Modal.Body>
          {alertMessage && <Alert variant="danger">{alertMessage}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          <Form.Group controlId="formFileUpload">
            <Form.Label>
              <FormattedMessage
                id="fileUploadInstructions"
                defaultMessage="Select IMS standard based question XML file and click ok"
              />
            </Form.Label>

            <Form>
              <Form.Label>
                <FormattedMessage id="message.selectfile" defaultMessage="Select file" />:
              </Form.Label>
              <Form.Control type="file" onChange={handleFileChange} accept=".zip" />
            </Form>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowImportModal(false)}>
            <FormattedMessage id="message.cancel" defaultMessage="Cancel" />
          </Button>
          <Button variant="primary" onClick={handleImport} disabled={!file}>
            <FormattedMessage id="message.ok" defaultMessage="OK" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImportQuestion;
